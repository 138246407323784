// material-ui
import { useTheme } from '@mui/material/styles';
import LogoImg from "../assets/images/logo-active.png"

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <img style={{with:'144px',height:"58px"}} src={LogoImg} alt="" />
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        // <svg width="144" height="58" viewBox="0 0 144 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M61.5131 57.9382C60.8046 57.9382 60.1624 57.7957 59.5864 57.5137C59.0104 57.2303 58.5121 56.8461 58.0916 56.3598C57.6712 55.8735 57.3472 55.3267 57.1211 54.7181C56.895 54.1095 56.7812 53.485 56.7812 52.8419C56.7812 52.1714 56.9022 51.5326 57.1427 50.9239C57.3832 50.3153 57.7187 49.7729 58.1492 49.2966C58.5784 48.8204 59.0824 48.442 59.6584 48.1643C60.2344 47.8866 60.8622 47.747 61.5433 47.747C62.2518 47.747 62.894 47.8938 63.47 48.1859C64.046 48.478 64.5414 48.8708 64.9576 49.3614C65.3737 49.852 65.6948 50.4002 65.9209 51.0031C66.1484 51.6074 66.2607 52.2261 66.2607 52.8577C66.2607 53.5282 66.1427 54.167 65.9065 54.7756C65.6703 55.3843 65.3377 55.9267 64.9071 56.4029C64.478 56.8792 63.974 57.2547 63.398 57.5281C62.822 57.8 62.1942 57.9382 61.5131 57.9382ZM58.07 52.8404C58.07 53.3498 58.1521 53.8433 58.3177 54.3195C58.4833 54.7958 58.7166 55.2188 59.019 55.5871C59.3214 55.9555 59.6843 56.2475 60.1105 56.4648C60.5353 56.6821 61.0076 56.79 61.5275 56.79C62.066 56.79 62.5484 56.6763 62.9732 56.4504C63.398 56.2245 63.7595 55.9224 64.0576 55.544C64.3556 55.167 64.5817 54.7425 64.7372 54.2692C64.8928 53.7972 64.9705 53.321 64.9705 52.839C64.9705 52.3297 64.887 51.839 64.7228 51.3671C64.5572 50.8952 64.3211 50.4722 64.0144 50.0995C63.7076 49.7268 63.3433 49.4348 62.9228 49.2218C62.5024 49.0089 62.0372 48.9038 61.5275 48.9038C60.9889 48.9038 60.508 49.0146 60.0817 49.2362C59.6569 49.4578 59.2955 49.7585 58.9974 50.1355C58.6993 50.5124 58.4704 50.9355 58.3105 51.4031C58.1507 51.8721 58.07 52.3512 58.07 52.8404Z" fill="#cccccc" />
        //     <path d="M76.8393 50.1657V57.8662H75.5635V47.8147H76.6406L82.7894 55.672V47.8291H84.0638V57.8662H82.9017L76.8393 50.1657Z" fill="#cccccc" />
        //     <path d="M93.2461 57.8662V47.8147H94.5219V56.7339H100.132V57.8662H93.2461Z" fill="#cccccc" />
        //     <path d="M108.321 57.8662V47.8147H109.597V57.8662H108.321Z" fill="#cccccc" />
        //     <path d="M120.38 50.1657V57.8662H119.104V47.8147H120.182L126.33 55.672V47.8291H127.605V57.8662H126.443L120.38 50.1657Z" fill="#cccccc" />
        //     <path d="M144 56.7339V57.8662H137.172V47.8147H143.874V48.947H138.448V52.203H143.165V53.2649H138.448V56.7339H144Z" fill="#cccccc" />
        //     <path d="M73.5781 35.9963V33.3517H82.9885V35.9963H73.5781Z" fill="#FC5719" />
        //     <path d="M86.0127 44.1558V25.1908H92.749C94.7981 25.1908 96.5088 25.6138 97.8811 26.4598C99.2535 27.3058 100.283 28.4454 100.968 29.8784C101.654 31.3115 101.998 32.9014 101.998 34.6466C101.998 36.5703 101.619 38.2437 100.862 39.6681C100.105 41.0925 99.0303 42.1975 97.6407 42.9802C96.2511 43.7644 94.6196 44.1558 92.749 44.1558H86.0127ZM98.9511 34.6466C98.9511 33.3114 98.7063 32.1316 98.2152 31.1072C97.7256 30.0827 97.0215 29.2813 96.1028 28.7029C95.184 28.1245 94.0666 27.8353 92.7476 27.8353H89.0064V41.5112H92.749C94.0853 41.5112 95.2128 41.2091 96.1316 40.6033C97.0488 39.9976 97.7487 39.1789 98.2296 38.1458C98.7106 37.1142 98.9511 35.9473 98.9511 34.6466Z" fill="#FC5719" />
        //     <path d="M114.401 44.2896C113.028 44.2896 111.79 44.0219 110.684 43.4881C109.578 42.9543 108.625 42.2277 107.824 41.3112C107.022 40.3947 106.403 39.3616 105.967 38.2134C105.53 37.0653 105.312 35.8854 105.312 34.674C105.312 33.4093 105.542 32.2035 106.007 31.0554C106.471 29.9072 107.112 28.8827 107.932 27.9835C108.752 27.0842 109.719 26.372 110.832 25.8468C111.946 25.3217 113.154 25.0584 114.454 25.0584C115.809 25.0584 117.043 25.3346 118.156 25.8857C119.269 26.4382 120.224 27.1778 121.018 28.1029C121.81 29.0295 122.424 30.0626 122.862 31.2007C123.299 32.3402 123.517 33.5071 123.517 34.6999C123.517 35.9646 123.29 37.1703 122.836 38.3199C122.381 39.4681 121.745 40.4925 120.925 41.3918C120.106 42.291 119.138 42.9989 118.025 43.5155C116.909 44.032 115.701 44.2896 114.401 44.2896ZM108.359 34.674C108.359 35.5646 108.501 36.4279 108.786 37.2653C109.071 38.1027 109.477 38.8451 110.003 39.4954C110.529 40.1458 111.167 40.658 111.914 41.0306C112.663 41.4047 113.499 41.5918 114.427 41.5918C115.389 41.5918 116.245 41.3961 116.993 41.0047C117.742 40.6134 118.369 40.0825 118.878 39.4149C119.386 38.7472 119.773 37.9991 120.041 37.1703C120.309 36.343 120.442 35.5099 120.442 34.6725C120.442 33.7819 120.303 32.9229 120.027 32.0956C119.75 31.2669 119.346 30.5287 118.81 29.8784C118.276 29.2281 117.638 28.7158 116.899 28.3432C116.159 27.9691 115.335 27.7821 114.427 27.7821C113.465 27.7821 112.609 27.9777 111.861 28.3691C111.112 28.7604 110.48 29.2871 109.963 29.9446C109.446 30.6036 109.048 31.3431 108.773 32.1618C108.497 32.9819 108.359 33.8193 108.359 34.674Z" fill="#FC5719" />
        //     <path d="M127.023 34.5128C127.023 33.356 127.228 32.2208 127.638 31.1072C128.047 29.995 128.649 28.9792 129.443 28.0626C130.235 27.1461 131.202 26.4152 132.343 25.8713C133.483 25.3289 134.775 25.0569 136.219 25.0569C137.93 25.0569 139.409 25.444 140.657 26.2195C141.904 26.9936 142.832 27.9864 143.438 29.1979L141.085 30.7734C140.728 30.0079 140.279 29.4022 139.736 28.9561C139.191 28.5115 138.604 28.1993 137.972 28.0209C137.34 27.8425 136.72 27.7533 136.114 27.7533C135.116 27.7533 134.242 27.9533 133.495 28.3547C132.746 28.7547 132.118 29.2856 131.61 29.9432C131.102 30.6021 130.718 31.3417 130.461 32.1604C130.203 32.9791 130.073 33.7992 130.073 34.6179C130.073 35.5258 130.225 36.4034 130.528 37.2494C130.831 38.0955 131.254 38.8437 131.798 39.494C132.341 40.1443 132.988 40.6566 133.737 41.0292C134.485 41.4033 135.305 41.5903 136.196 41.5903C136.801 41.5903 137.435 41.4839 138.094 41.2695C138.754 41.0566 139.364 40.7213 139.926 40.2681C140.487 39.8134 140.928 39.2134 141.249 38.4652L143.735 39.881C143.343 40.8249 142.732 41.6263 141.903 42.2853C141.075 42.9443 140.148 43.4435 139.122 43.7817C138.097 44.1198 137.086 44.2896 136.088 44.2896C134.752 44.2896 133.531 44.009 132.425 43.4479C131.32 42.8867 130.366 42.1385 129.565 41.2033C128.763 40.2681 128.139 39.2177 127.693 38.0509C127.247 36.8869 127.023 35.707 127.023 34.5128Z" fill="#FC5719" />
        //     <path d="M59.4752 28.372V27.8353H70.3227V25.1908H56.4814V31.1762L59.4752 28.372Z" fill="#cccccc" />
        //     <path d="M59.4752 41.5112V35.7157H68.6581V33.2309H59.4752V33.2237L56.4814 36.0279V44.1558H70.5632V41.5112H59.4752Z" fill="#FC5719" />
        //     <path d="M16.9061 23.9059H9.82129V16.073C9.82129 7.21133 17.0371 0 25.9075 0H28.4404C37.3094 0 44.5266 7.20989 44.5266 16.073V19.2067H37.4419V16.073C37.4419 11.1148 33.4041 7.0804 28.4419 7.0804H25.906C20.9438 7.0804 16.9061 11.1148 16.9061 16.073V23.9059Z" fill="#cccccc" />
        //     <path d="M4.91895 28.854V28.3648C4.91895 26.9001 6.10694 25.713 7.57286 25.713H30.548C31.713 25.713 32.659 24.7692 32.659 23.6037C32.659 22.3059 33.7117 21.2542 35.0106 21.2542H44.5376C47.2347 21.2542 49.422 23.4397 49.422 26.1346V49.3312L45.1827 31.274C44.8284 29.9834 43.6534 28.736 42.3142 28.7403L4.91895 28.854Z" fill="#FC5719" />
        //     <path d="M25.5372 45.8665C26.3652 44.9644 26.6993 43.6378 26.4329 42.3184C26.0066 40.212 24.1822 38.7947 22.3692 39.1616C20.5548 39.5285 19.4244 41.54 19.8507 43.6464C20.0811 44.7888 20.7348 45.7658 21.6305 46.3384C22.1071 46.6435 22.4455 47.1211 22.519 47.6823L22.9798 51.2664C23.0474 51.7901 23.4938 52.1829 24.0223 52.1829H26.79C27.6036 52.1829 28.109 51.298 27.6943 50.5973L25.4393 46.7917C25.2622 46.4953 25.3025 46.124 25.5372 45.8665Z" fill="#cccccc" />
        //     <path d="M48.5412 55.3109L43.1887 32.8078C42.954 31.8237 42.0742 31.1287 41.0618 31.1287H2.18769C0.760658 31.1287 -0.284779 32.4726 0.06946 33.8538L5.82657 56.3569C6.07424 57.3238 6.94544 58 7.9448 58H46.4143C47.8269 58.0014 48.8681 56.6835 48.5412 55.3109ZM29.8039 52.8505C29.6657 53.0936 29.4051 53.2447 29.1257 53.2447H22.8488C22.4571 53.2447 22.123 52.9512 22.074 52.5627L21.416 47.4391C20.0955 46.7557 19.1264 45.4276 18.8096 43.8565C18.2681 41.176 19.7701 38.6034 22.1604 38.1214C24.548 37.6408 26.934 39.4278 27.4769 42.1083C27.7966 43.6867 27.4164 45.2852 26.4603 46.4276L29.7996 52.0663C29.9393 52.3066 29.9422 52.6088 29.8039 52.8505Z" fill="#FC5719" />
        // </svg>
    );
};

export default Logo;
